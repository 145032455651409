.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

#video {
    position: fixed;
    width: 100%;
}

#logo {
    position: fixed;
    z-index: 2;
    top: 50px;
    right: 50px;
    width: 100px;
}

#robot {
    position: fixed;
    z-index: 2;
    top: 250px;
    right: 10px;
      animation-name : robotmove;
      animation-iteration-count : infinite;
      animation-direction : alternate;
      animation-duration : 20s;
}

@keyframes robotmove {
  from {
    transform: translateX(1000%) translateY(100%);
  }
  to {
    transform: translateX(-1000%) translateY(0%);
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
